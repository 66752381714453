import PropTypes from "prop-types";

import { ButtonArrow, getProperty, removeProperty } from "@gh/shared";

import { useMeta } from "hooks";

const THEME_LARGE = "LARGE";
const THEME_SMALL = "SMALL";

export const BookButton = ({ url, theme, ...props }) => {
  const meta = useMeta();
  const href = url || getProperty(meta, "meta.booking.url");
  const text_small = getProperty(meta, "meta.booking.text_small");
  const text_large = getProperty(meta, "meta.booking.text_large");

  return (
    <ButtonArrow wrapper="a" href={href} rel="noreferrer" target="_blank" {...props}>
      {theme === THEME_SMALL ? text_small : text_large}
    </ButtonArrow>
  );
};

BookButton.defaultProps = {
  url: "",
  className: "",
  theme: THEME_LARGE,
};

BookButton.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEME_SMALL, THEME_LARGE]),
};

export const BookButtonSmall = (props) => <BookButton {...props} theme={THEME_SMALL} />;
BookButtonSmall.defaultProps = removeProperty(BookButton.defaultProps, "theme");
BookButtonSmall.propTypes = removeProperty(BookButton.propTypes, "theme");

export const BookButtonLarge = (props) => <BookButton {...props} theme={THEME_LARGE} />;
BookButtonLarge.defaultProps = removeProperty(BookButton.defaultProps, "theme");
BookButtonLarge.propTypes = removeProperty(BookButton.propTypes, "theme");
