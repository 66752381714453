import { Fragment } from "react";
import { graphql } from "gatsby";

import { getProperty } from "@gh/shared";

import { useOffers, useServices } from "hooks";

import { Meta } from "./common/meta";
import { Intro } from "./offers/intro";
import { Items } from "./offers/items";
import { Services } from "./offers/services";

const Template = ({ data }) => {
  const offers = useOffers();
  const services = getProperty(useServices(), "services");

  const meta = getProperty(data, "markdownRemark.frontmatter.meta");
  const intro = getProperty(data, "markdownRemark.frontmatter.offers.intro");
  const items = {
    items: getProperty(offers, "offers.items"),
    categories: getProperty(offers, "offers.categories"),
    basePath: getProperty(offers, "offers.base_path"),
  };

  return (
    <Fragment>
      <Meta data={meta} />
      <Intro data={intro} />
      <Items data={items} />
      <Services data={services} />
    </Fragment>
  );
};

export default Template;

export const query = graphql`
  query ($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        path
        title
        meta {
          title
          description
        }
        offers {
          intro {
            title
            content
          }
        }
      }
    }
  }
`;
