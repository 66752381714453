import PropTypes from "prop-types";

import { Section } from "@gh/shared";

import { ServicesList } from "../common/services-list";

export const Services = ({ data }) => {
  return (
    <Section align="center">
      <ServicesList {...data} />
    </Section>
  );
};

Services.defaultProps = {
  data: {},
};

Services.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string.isRequired,
    images: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    labels: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  }).isRequired,
};
