import PropTypes from "prop-types";
import { Link } from "gatsby";

import { ButtonArrow, getProperty, removeProperty } from "@gh/shared";

import { useMeta } from "hooks";

const THEME_LARGE = "LARGE";
const THEME_SMALL = "SMALL";

export const RequestButton = ({ path, theme, ...props }) => {
  const meta = useMeta();
  const to = path || getProperty(meta, "meta.request.path");
  const text_small = getProperty(meta, "meta.request.text_small");
  const text_large = getProperty(meta, "meta.request.text_large");

  return (
    <ButtonArrow wrapper={Link} to={to} {...props}>
      {theme === THEME_SMALL ? text_small : text_large}
    </ButtonArrow>
  );
};

RequestButton.defaultProps = {
  url: "",
  className: "",
  theme: THEME_LARGE,
};

RequestButton.propTypes = {
  url: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf([THEME_SMALL, THEME_LARGE]),
};

export const RequestButtonSmall = (props) => <RequestButton {...props} theme={THEME_SMALL} />;
RequestButtonSmall.defaultProps = removeProperty(RequestButton.defaultProps, "theme");
RequestButtonSmall.propTypes = removeProperty(RequestButton.propTypes, "theme");

export const RequestButtonLarge = (props) => <RequestButton {...props} theme={THEME_LARGE} />;
RequestButtonLarge.defaultProps = removeProperty(RequestButton.defaultProps, "theme");
RequestButtonLarge.propTypes = removeProperty(RequestButton.propTypes, "theme");
