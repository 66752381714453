import { Fragment, useMemo, useEffect, useRef } from "react";
import { withPrefix } from "gatsby-link";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import { GatsbyImage } from "gatsby-plugin-image";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import cssEscape from "css.escape";

import { Section, Title, Text, getProperty, SM, MD, XL, XXL } from "@gh/shared";

import { useMeta } from "hooks";

import { EmptyState } from "../common/empty-state";
import { BookButtonSmall } from "../common/book-button";
import { RequestButtonSmall } from "../common/request-button";
import { MenuCategories } from "../common/menu-categories";
import { ButtonPrev, ButtonNext } from "../common/nav-button";

import * as styles from "./items.module.scss";

const prevEl = `.${cssEscape(styles.prev)}`;
const nextEl = `.${cssEscape(styles.next)}`;

export const Items = ({ data }) => {
  const meta = useMeta();
  const swiperRef = useRef(null);
  const scrollerRef = useRef(null);
  const { hash, search, pathname } = useLocation();

  const currentPath = `${pathname}${hash}`;
  const items = getProperty(data, "items") || [];
  const categories = getProperty(data, "categories") || [];
  const offerKey = getProperty(meta, "meta.params.offer");
  const requestPath = getProperty(meta, "meta.request.path");
  const categoryKey = getProperty(meta, "meta.params.category");
  const labelIncluded = getProperty(meta, "meta.offers.label_included");

  const initialSlide = useMemo(
    () => items.findIndex(({ path }) => withPrefix(path) === currentPath),
    [items, currentPath]
  );

  const queryParams = useMemo(() => {
    const searchParams = new URLSearchParams(search);

    return {
      category: searchParams.get(categoryKey),
    };
  }, [search]);

  const filteredItems = useMemo(
    () =>
      items.filter((item) => {
        const categories = getProperty(item, "categories") || [];
        return queryParams.category ? categories.includes(queryParams.category) : true;
      }),
    [items, queryParams.category]
  );

  const linkCategoryGenerator = (category) => {
    const value = getProperty(category, "value");
    return value ? `./?${categoryKey}=${value}` : ".";
  };

  const linkRequestGenerator = (item) => {
    const index = items.findIndex((current) => {
      const pathActual = getProperty(current, "path");
      const pathExpected = getProperty(item, "path");

      return pathActual === pathExpected;
    });

    return index >= 0 ? `${requestPath}/?${offerKey}=${index}` : requestPath;
  };

  useEffect(() => {
    if (filteredItems.length === 0) {
      return;
    }

    swiperRef.current.slideTo(0, 0);
  }, [filteredItems]);

  useEffect(() => {
    if (initialSlide < 0) {
      return;
    }

    scrollerRef.current.scrollIntoView();
    swiperRef.current.slideTo(initialSlide, 0);
  }, [initialSlide]);

  return (
    <Fragment>
      <Section align="center" size="md">
        <MenuCategories
          categories={categories}
          activeValue={queryParams.category}
          linkGenerator={linkCategoryGenerator}
        />
      </Section>

      <Section align="center" color="secondary">
        {filteredItems.length > 0 ? (
          <div className={styles.scroller} ref={scrollerRef}>
            <Swiper
              slidesPerView={1.25}
              centeredSlides={true}
              modules={[Navigation]}
              slideToClickedSlide={true}
              touchEventsTarget="container"
              navigation={{ prevEl, nextEl }}
              breakpoints={{
                [SM]: {
                  slidesPerView: 1.75,
                },
                [MD]: {
                  slidesPerView: 2.25,
                },
                [XL]: {
                  slidesPerView: 3.25,
                },
                [XXL]: {
                  slidesPerView: 4.25,
                },
              }}
              onSwiper={(swiper) => {
                swiperRef.current = swiper;
              }}
            >
              {filteredItems.map((item, index) => {
                const bookingUrl = getProperty(item, "url");
                const requestPath = linkRequestGenerator(item);

                const title = getProperty(item, "title");
                const text = getProperty(item, "details.text");
                const price = getProperty(item, "summary.price");
                const services = getProperty(item, "details.services") || [];
                const image = getProperty(item, "image_large.childImageSharp.gatsbyImageData");

                return (
                  <SwiperSlide key={index}>
                    {({ isActive }) => (
                      <div className={`${styles.slide} ${isActive ? styles.active : ""}`}>
                        <div className={styles.header}>
                          <Title size="s6" weight="w4" className={styles.title}>
                            {title}
                          </Title>
                          <Text className={styles.text}>{text}</Text>
                          <Text size="s5" className={styles.price}>
                            {price}
                          </Text>
                          <div className={styles.buttons}>
                            <RequestButtonSmall to={requestPath} />
                            <BookButtonSmall url={bookingUrl} />
                          </div>
                        </div>
                        <div className={styles.image}>
                          <GatsbyImage image={image} alt={title} />
                        </div>
                        <div className={styles.footer}>
                          <Title
                            wrapper="h3"
                            size="s4"
                            family="f2"
                            weight="w4"
                            transform="uppercase"
                            className={styles.title}
                          >
                            {labelIncluded}
                          </Title>
                          <ul className={styles.services}>
                            {services.map((service, index) => (
                              <li key={index} className={styles.service}>
                                {service}
                              </li>
                            ))}
                          </ul>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                );
              })}
            </Swiper>
            <div className={styles.arrows}>
              <ButtonPrev className={styles.prev} />
              <ButtonNext className={styles.next} />
            </div>
          </div>
        ) : (
          <EmptyState />
        )}
      </Section>
    </Fragment>
  );
};

Items.defaultProps = {
  data: {},
};

Items.propTypes = {
  data: PropTypes.shape({
    items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
    categories: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
  }),
};
