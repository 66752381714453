// extracted by mini-css-extract-plugin
export var active = "items-module--active--9RhbF";
export var arrows = "items-module--arrows--FPxQt";
export var breakpoint_lg = "75rem";
export var breakpoint_md = "64rem";
export var breakpoint_sm = "48rem";
export var breakpoint_xl = "90rem";
export var breakpoint_xs = "30rem";
export var breakpoint_xxl = "120rem";
export var breakpoint_xxs = "15rem";
export var buttons = "items-module--buttons--bGLf9";
export var category = "items-module--category--lKVrh";
export var color_primary = "#82c17a";
export var color_secondary = "#ece8e1";
export var footer = "items-module--footer--Z4odG";
export var header = "items-module--header--CtUxo";
export var icon = "items-module--icon--82A-P";
export var image = "items-module--image--7-L-S";
export var menu = "items-module--menu--rP2xW";
export var next = "items-module--next--1LLYy";
export var prev = "items-module--prev--OHsmm";
export var price = "items-module--price--d9ZEy";
export var scroller = "items-module--scroller--olZoI";
export var service = "items-module--service--gszOu";
export var services = "items-module--services--LICVu";
export var slide = "items-module--slide--uKQDC";
export var text = "items-module--text--LLlio";
export var title = "items-module--title--69ZKi";